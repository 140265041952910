// main: ../../production.scss

.section--logo--bar {
	.slick-slide {
		display: inline-block !important;
		vertical-align: middle !important;
		margin: 0 20px;
		float: none !important;

		// > div {
		// 	padding: 10px;
		// }

		img {
			max-height: 70px !important;
			width: auto;
			margin: 0 auto;
		}
		.slick-track {
			margin: 0 auto;
		}
	}

	// Fix external margins
	.slick-list {
		margin: 0 -10px;
	}
	.slick-slider {
		overflow: hidden;
	}

	.logo--item:focus{
		outline:0!important
	}

	.logo-slider-arrow{
		position:absolute;
		top:20%
	}

	.logo-slider-arrow a {
		cursor:pointer;
		color: color('primary');
	}

	.logo-slider-arrow a:hover{
		color: lighten(color('primary'),20%);
	}
	.logo-slider-arrow.next{
		right:5px
	}

	.logo-slider-arrow.prev {
		left:5px
	}
	@include media-query('>tablet') {
		.logo-slider-arrow.next{
			right:-20px
		}

		.logo-slider-arrow.prev {
			left:-20px
		}
	}
	ul.slick-dots {
		margin-top: 30px;
		* {
			&:focus {
				outline: none !important;
			}
		}
		text-align: center;
		padding: 20px;
		li {
			display: inline-block;
			padding: 0 5px;
			button {
				background: #e2e2e2;
				border-radius: 100%;
				display: block;
				width: 10px;
				height: 10px;
				line-height: 10px;
				border: 0;
				padding: 0;
				opacity: 1;
				font-size: 0;
			}
			&.slick-active {
				button {
					background: color('blue');
					width: 10px;
					height: 10px;
					opacity: 1;
				}
			}
		}
	}
}

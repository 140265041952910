// main: ../../production.scss

.woocommerce-page.single-product{
	.section--product--tabs {
		.product--tab--triggers {
			.product--tab--trigger {
				cursor: pointer;
				border-left: 2px solid color('white');
				border-right: 2px solid color('white');
				width: 25%;
				text-align: center;
				padding: 0 !important;
				.block {
					background: color('lightgrey');
					padding: 30px 0;
					box-shadow: none;
					&.active {
						border-left: none;
						border-right: none;
						background: color('white');
					}
				}
			}
		}
		.product--tab--content {
			padding: 30px;
		}
	}
}

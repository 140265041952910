// main: ../../production.scss

.section--testimonialslider--slider .flexslider--slide--outerwrapper {
	display:block;
	position:relative;
	padding-left:35px;
	padding-right:35px;
}
.section--testimonialslider .flexslider--slide {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.section--testimonialslider--image {
	display:block;
	width:46px;
	height:46px;
	border-radius:50%;
}
.section--testimonialslider--source {
	max-width:350px;
}
.section--testimonialslider--source,
.section--testimonialslider--source p {
	line-height:1.4em;
	font-size:20px;
}
.section--testimonialslider--hassource.section--testimonialslider--hasimage {
	text-align:left;
}
.section--testimonialslider--hassource.section--testimonialslider--hasimage .section--testimonialslider--image {
	float:left;
	margin-right:14px;
}
.section--testimonialslider--hassource.section--testimonialslider--hasimage .section--testimonialslider--source {
	display:table;
	margin-top:0px;
}
.section--testimonialslider .flexslider--prev,
.section--testimonialslider .flexslider--next {
	color:color('primary') !important;
	transition: .3s color ease-in-out;
	font-size:30px;
	padding: 30px;
}
.section--testimonialslider .flexslider--prev {
	margin-left:0px;
	left:0;
}
.section--testimonialslider .flexslider--next {
	margin-right:0px;
	right:0;
}
.section--testimonialslider .flexslider--prev:hover,
.section--testimonialslider .flexslider--next:hover {
	color: lighten(color('primary'),20%);
}

.section--testimonialslider .flexslider--controls li {
	width:14px;
	height:14px;
	margin:0px 5px;
	opacity:1;
	filter:alpha(opacity=100);
	transform:scale(.8);
	background:color("grey");
	transition: background .3s ease-in-out,transform .3s ease-in-out;
}

.section--testimonialslider .flexslider--controls li.active {
	background:color("secondary");
}

@include media-loop() {
	.section--testimonialslider .#{$media}all--color--white .flexslider--controls li {
		background:rgba(255,255,255,.5)!important;
	}
	.section--testimonialslider .#{$media}all--color--white .flexslider--controls li.active {
		background:color("white")!important;
	}
}
.section--testimonialslider .flexslider--slide:hover {
	cursor:grab;
}
.section--testimonialslider .flexslider--slide:first-child {
	display:block;
	position:relative;
}
.section--testimonialslider .flexslider--slider.flexslider--initialized .flexslider--slide {
	display:block;
	position:absolute!important;
	top: 50%;
	transform: translateY(-50%);
}

@include media-query('palm') {
	.section--testimonialslider--hassource.section--testimonialslider--hasimage {
		text-align:center;
	}
	.section--testimonialslider--hassource.section--testimonialslider--hasimage .section--testimonialslider--image {
		display:inline-block;
		float:none;
		margin-right:0px;
		margin-bottom:20px;
	}
	.section--testimonialslider--hassource.section--testimonialslider--hasimage .section--testimonialslider--source {
		display:block;
		margin-top:0px;
	}
}

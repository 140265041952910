// main: ../production.scss

/* Fades & Animations */

.fade--in,
.fade--up,
.fade--down,
.fade--left,
.fade--right,
.fade--load {
	opacity:0;
	@include transition(opacity 1.25s ease-out);
}
.fade--left,
.fade--right {
	-webkit-transition: opacity 1.25s ease-out, transform 1.25s ease-out;
	-moz-transition: opacity 1.25s ease-out, transform 1.25s ease-out;
	-ms-transition: opacity 1.25s ease-out, transform 1.25s ease-out;
	-o-transition: opacity 1.25s ease-out, transform 1.25s ease-out;
	transition: opacity 1.25s ease-out, transform 1.25s ease-out;
}
.fade--up:before,
.fade--up:after,
.fade--down:before,
.fade--down:after {
	@include transition(margin 1.25s ease-out);
}

.fade--up {
	&:before,
	&:after {
		content:"";
		display:block;
		width: 100%;
		height: 0px;
	}
	&:before {
		margin-top:20px;
	}
	&:after {
		margin-bottom:-20px;
	}
}
.fade--down {
	&:before,
	&:after {
		content:"";
		display:block;
		width: 100%;
		height: 0px;
	}
	&:before {
		margin-top:-20px;
	}
	&:after {
		margin-bottom:20px;
	}
}
.fade--left {
    transform: translateX(100px);
}
.fade--right {
    transform: translateX(-100px);
}

.fade {
	&.fade--in, &.fade--up, &.fade--down, &.fade--left, &.fade--right, &.fade--load {
		opacity:1;
	}
	&.fade--up, &.fade--down {
		&:before {
			margin-top:0px;
		}
		&:after {
			margin-bottom:0px;
		}
	}
	&.fade--left, &.fade--right {
		transform: translateX(0);
	}
}

@for $i from 1 through 10 {
	.fade--delay--#{$i}, .fade--delay--#{$i}:before, .fade--delay--#{$i}:after {
		@include transition-delay(#{($i * .33)}s);
	}
}

html.no-js, html.hs-inline-edit {
	.fade--in, .fade--up, .fade--down, .fade--left, .fade--right {
		opacity:1!important;
		filter:alpha(opacity=100)!important;
	}
	&.fade--up, &.fade--down {
		&:before, &:after {
			content:none!important;
		}
	}
}


@include media-query(touch) {
	.fade--in,
	.fade--up,
	.fade--down,
	.fade--left,
	.fade--right {
		opacity:1;
	}
	.fade--left {
    	transform: translateX(0);
	}
	.fade--right {
	    transform: translateX(0);
	}
	.fade--up, .fade--down {
		&:before {
			margin-top:0px;
		}
		&:after {
			margin-bottom:0px;
		}
	}
}

span.defer--image, div.defer--image {
	background-repeat: no-repeat !important;
	background-position: center center !important;
	&.background--contain {
		background-size: contain !important;
	}
	&.background--cover {
		background-size: cover !important;
	}
}

// main: ../../production.scss

.section--faqs {
	.section--faqs--faq {
		display:block;
		position:relative;
		border-bottom:1px solid #d4d4d4;
	}
	.section--faqs--faq--toggle {
		display:block;
		position:relative;
		padding-right:40px;
		padding-top: $short;
		padding-bottom: $short;
	}
	.section--faqs--faq--toggle:after {
		content:"\f067";
		display:block;
		position:absolute;
		top:50%;
		right:0;
		font-size:20px;
		line-height:30px;
		margin-top:-15px;
		font-family: font("fontawesome");
		font-weight:900;
		color: color("primary");
		transition: color .3s ease-in-out;
	}
	.section--faqs--faq--toggle:hover {
		cursor:pointer;
	}
	.section--faqs--faq--toggle:hover:after {
		color: darken(color("primary"), 10%);
	}
	.section--faqs--faq.active .section--faqs--faq--toggle:after {
		content:"\f068";
	}
	.section--faqs--faq--content {
		width:100%;
	}
	.section--faqs--faq--content--inner--spacer {
		display:block;
		width:100%;
		height: $short;
	}
	@include media-loop() {
		.#{$media}all--color--white .section--faqs--faq {
			border-bottom-color: color("white");
		}
		.#{$media}all--color--white .section--faqs--faq--toggle:after {
			color: color("white") !important;
		}
	}
}
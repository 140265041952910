// main: ../custom.scss

.section--latestcontent {

	.content {
		ul {
			list-style: none;
			display: block !important;
			position: relative;
			padding-left: 30px;
			li {
				display: block;
				margin: 0 0 20px 0;
				position: relative;
				font-size: 18px;
				color: color('grey');
				line-height: 1.45em;
				&:before {
					content: '\f111';
					display: inline-block;
					font-family: 'Font Awesome 5 Free';
					font-weight: 900;
					position: absolute;
					top: 0;
					left: -30px;
					font-size: 12px;
					color: color('secondary');
					vertical-align: top;
				}
			}
		}
		&.all--bullets--check {
			ul {
				li {
					&:before {
						content: '\f00c';
					}
				}
			}
		}
		&.all--bullets--dots {
			ul {
				li {
					&:before {
						content: '\f111';
					}
				}
			}
		}
	}
	.result--block--content {
		a {
			i {
				transform: translateX(0);
				transition: transform .3s ease-in-out;
			}
			&:hover {
				i {
					transform: translateX(5px);
				}
			}
		}
		.result--title {
			transition: color .3s ease-in-out;
		}
		&:hover {
			.result--title {
				color: color('primary');
			}
		}
		.result--block--content--meta {
			> a {
				font-weight: $bold;
			}
		}
	}
	.section--ctas {
		.btn {
			overflow: hidden;
			position: relative;
			border-radius: 0 !important;
			text-align: left;
			padding: 20px;
			> span.button--text {
				display: block;
				position: relative;
				z-index: 3;
				&:after {
					content: '\f105';
					font-family: Fontawesome, 'Font Awesome 5 Free';
					font-weight: 900;
					display: inline-block;
					transform: translateX(0);
					transition: all .3s ease-in-out;
					font-size: 15px;
				}
			}
			span.grow--width {
				z-index: 2;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				display: block;
				content: '';
				width: 0;
				transition: width .3s ease-in-out;
			}
			&:hover {
				span.button--text {
					color: color('white') !important;
					text-decoration: none;
					&:after {
						transform: translateX(5px);
					}
				}
				span.grow--width {
					width: 100%;
				}
			}
		}
	}
}

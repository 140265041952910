// main: ../../production.scss

.section--people-listing {
	.section--peoplelisting--inner {
		display:block;
		margin-left:-15px;
		margin-right:-15px;
	}
	.section--peoplelisting--item--inner {
		display:block;
		padding:15px;
	}
	.section--peoplelisting--item--content {
		display:block;
		text-align:left;
		padding:20px 0;
		.p {
			margin-top: 0;
		}
	}
	.section--peoplelisting--item--social {
		a {
			display:inline-block;
			vertical-align:middle;
			margin:0px 8px 0 0;
			border-radius: 3px;
			width:18px;
			height:21px;
			text-align:center;
			line-height:21px;
			// background: color("primary");
			color: color("primary");
			font-size:21px;
		}
	}
}
.fancybox-container {
	.fancybox-content {
		max-width: 800px !important;
		padding: 0 !important;
	}
}
// main: ../production.scss

.overlay {
	display:block;
 	position:absolute;
 	top:0;
 	left:0;
 	width:100%;
 	height:100%;
}

.placeholder, .spacer {
	display:block;
	width:100%;
	height:auto;
	opacity:0;
	filter:alpha(opacity=0);
	visibility:hidden;
}

.background--image {
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: center !important;
}

.box-shadow, .box--shadow {
	box-shadow:0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.inner-shadow, .inner--shadow {
	-webkit-box-shadow: inset 0px 15px 25px 0px rgba(87, 87, 87, 0.2);
	-moz-box-shadow: inset 0px 15px 25px 0px rgba(87, 87, 87, 0.2);
	box-shadow: inset 0px 15px 25px 0px rgba(87, 87, 87, 0.2);
}

.no--shadow {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.clip-box {
	&__wrapper {
  	transition: all .3s ease-in-out;
  	filter: drop-shadow(0px 0px 10px rgba(111, 111, 111, 0.5));
  	cursor:pointer;

		&:hover {
			filter: drop-shadow(0px 0px 20px rgba(111, 111, 111, 0.8));
		}

		.box {
			width:200px;
			display:inline-block;
			aspect-ratio:1;
			background: white;
			clip-path: polygon(0% 0%, 70% 0%, 100% 30%, 100% 100%, 0% 100%);
			box-shadow: 0 10px 30px 30px rgba(0,0,0,.5);
		}
	}
}

.clip--shadow {
	filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.1));
}

.corner--clip {
	clip-path: polygon(0 0, calc( 100% - 100px ) 0, 100% 120px, 100% 100%, 0 100%);
}

@include media-query('lap-and-up') {
	.parallax {
		background-attachment: fixed !important;
	}
}

.video--cover, .video--overlay, .video--play {
	&:before {
		content: '';
		background-image: url('#{$video--play-icon-url}');
		background-repeat: no-repeat;
		background-size: $video--play-icon-size;
		background-position: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.valign--flex {
	height: 100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
  display: flex !important;
  flex-wrap: wrap;
  flex-flow: column;
  align-items: center;
	justify-content: center;
}

.background {
  display:block;
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index:1;
}
.background--layer {
  display:block;
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
}
.background--video {
  background-size:cover;
  opacity:0;
  filter:alpha(opacity=0);
  @include transition("opacity .2s ease-in-out");
}
.background--video.background--video--active {
  opacity:1;
  filter:alpha(opacity=100);
}
.background--video[poster] {
  object-fit:cover;
}
img.spacer {
  display:block;
  width:100%;
  height:auto;
  opacity:0;
  filter:alpha(opacity=0);
  visibility:hidden;
}

.background-size--cover {background-size:cover!important;}
.background-size--contain {background-size:contain!important;}
.background-size--contain--y {background-size:auto 100%!important;}
.background-size--contain--x {background-size:100% auto!important;}
.background-size--stretch {background-size:100% 100%!important;}
.background-size--auto {background-size:auto!important;}

.background-repeat {background-repeat:repeat!important;}
.background-repeat--y {background-repeat:repeat-y!important;}
.background-repeat--x {background-repeat:repeat-x!important;}
.background-repeat--none {background-repeat:no-repeat!important;}

.block--video {
  position:relative;
	&:before {
		content:"";
		background-image: url("data:image/svg+xml,%3Csvg width='100' height='101' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='50' cy='50.5' rx='50' ry='50.5' fill='%23E35205'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M67 50.3306L39 67V35L67 50.3306Z' fill='white'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 100px;
		background-position: center;
		display:block;
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		opacity:1;
		transition: opacity .3s ease-in-out;
	}
	&.product--videos {
		&:before {
			background-size: 150px;
		}
	}
	&:hover {
  	cursor:pointer;
		&:before {
			opacity: 0.6;;
		}
	}
}

// main: ../../production.scss

.section--hero {
	@include media-query('palm') {
		background-position: 40% 75% !important;
		p span.inline-block {
			display: block!important;
			margin-top: 10px;
		}
	}
	.typed + span.typed-cursor {
		font-size: $h4--font-size;
		font-family: 'BebasNeue', sans-serif;
		line-height: 1.2em;
		color: black;
		font-weight: normal;
	}
	.typed--holder {
		min-height: 70px;
	}
	.typed {
		text-transform: none !important;
	}
	.typed-cursor {
		animation: blink 0.7s infinite;
	}
	@keyframes blink {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	#typed-strings {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		height: 0;
	}
}
.elementor-editor-active {
	#typed-strings {
		opacity: 1;
		visibility: visible;
		position: relative;
		height: auto;
	}
}

// main: ../../production.scss

.section--relatedpostsslider {
	.post--slider{
		.block--video {
			&:before {
				@include media-query('lap') {
					top: 90px;
				}
				@include media-query('desk') {
					top: 80px;
				}
			}
		}
		.result--item {
			margin:20px;
			&:hover {
				box-shadow: 0 5px 10px 0 rgba(65,65,65,0.1);
			}
			.content--lower {
				padding:10px;
			}
		}
	}

	.slick-slide {
		display: inline-block !important;
		vertical-align: top !important;
		margin: 0 10px;
		float: none !important;

		// > div {
		// 	padding: 10px;
		// }

		// img {
		// 	max-height: 70px !important;
		// 	width: auto;
		// 	margin: 0 auto;
		// }
		.slick-track {
			margin: 0 auto;
		}
	}

	// Fix external margins
	.slick-list {
		margin: 0 -10px;
	}
	.slick-slider {
		overflow: hidden;
	}

	.post--item:focus{
		outline:0!important
	}

	.post-slider-arrow{
		position:absolute;
		top:30%
	}

	.post-slider-arrow a {
		cursor:pointer;
		color: color('primary');
		padding: 30px;
		transition: color .3s ease-in-out;
	}

	.post-slider-arrow a:hover{
		color: lighten(color('primary'),20%);
	}
	.post-slider-arrow.next{
		right:5px
	}

	.post-slider-arrow.prev {
		left:5px
	}
	@include media-query('>tablet') {
		.post-slider-arrow.next{
			right:-60px
		}

		.post-slider-arrow.prev {
			left:-60px
		}
	}
	ul.slick-dots {
		margin-top: 30px;
		* {
			&:focus {
				outline: none !important;
			}
		}
		text-align: center;
		padding: 20px;
		li {
			display: inline-block;
			padding: 0 5px;
			button {
				background: #e2e2e2;
				border-radius: 100%;
				display: block;
				width: 10px;
				height: 10px;
				line-height: 10px;
				border: 0;
				padding: 0;
				opacity: 1;
				font-size: 0;
			}
			&.slick-active {
				button {
					background: color('blue');
					width: 10px;
					height: 10px;
					opacity: 1;
				}
			}
		}
	}
}

// main: ../../production.scss

.flexslider--slider { display: block; width: 100%; overflow: visible; position: relative; }
.flexslider--slider .flexslider--slide--wrapper { position: relative; width: 100%; overflow: hidden; }
.flexslider--slider .flexslider--slide { position: absolute; width: 100%; }
.flexslider--slider.flexslider--type--slide .flexslider--slide { -webkit-transition: left 0.5s ease-in-out; -moz-transition: left 0.5s ease-in-out; -ms-transition: left 0.5s ease-in-out; -o-transition: left 0.5s ease-in-out; transition: left 0.5s ease-in-out; }
.flexslider--slider.flexslider--type--fade .flexslider--slide { opacity: 0; filter: alpha(opacity=0); -webkit-transition: opacity 0.5s ease-in-out; -moz-transition: opacity 0.5s ease-in-out; -ms-transition: opacity 0.5s ease-in-out; -o-transition: opacity 0.5s ease-in-out; transition: opacity 0.5s ease-in-out; }
.flexslider--slider.flexslider--type--fade .flexslider--slide.active { opacity: 1; filter: alpha(opacity=100); }
.flexslider--slider.flexslider--type--static .flexslider--slide {left:0%;}

.flexslider--prev, .flexslider--next { position: absolute; top: 50%; display: block; height: 40px; width: 40px; background: transparent; margin-top: -20px; font-size: 30px; line-height: 40px; text-align: center; color: #7c7c7c; }
.flexslider--prev:hover, .flexslider--next:hover { cursor: pointer; }

.flexslider--prev { left: 0; margin-left: -60px; }

.flexslider--next { right: 0; margin-right: -60px; }

.flexslider--autoslide { display: block; visibility: hidden; position: absolute; width: 0%; }

.flexslider--controls { display: block; text-align: center; padding: 20px 0px 0px;margin:0px; }
.flexslider--controls li { display: inline-block; width: 16px; height: 16px; border-radius: 50%; margin: 0px 8px; background: #7c7c7c; opacity: .7; filter: alpha(opacity=70); -webkit-transition: opacity 0.3s ease-out; -moz-transition: opacity 0.3s ease-out; -ms-transition: opacity 0.3s ease-out; -o-transition: opacity 0.3s ease-out; transition: opacity 0.3s ease-out; }
.flexslider--controls li:hover, .flexslider--controls li.active { opacity: 1; filter: alpha(opacity=100); cursor: pointer; }
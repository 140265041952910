@include media-query('>tablet') {
	.section--leftrightfloat--media {
		display:block;
		position:absolute;
		top:0;
		width: 31.833333333%;
		height:100%;
	}
	.section--leftrightfloat--media--wrapper {
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
	}
	.section--leftrightfloat--right div.section--leftrightfloat--media {
		left:0;
	}
	.section--leftrightfloat--left div.section--leftrightfloat--media {
		right:0;
	}
}

@include media-query('palm') {
	.section--leftrightfloat--media--wrapper {
		background:none!important;
		img {
			height: auto !important;
		}
	}
}

.section--leftrightfloat--left .block--video--icon--outer {
	position: relative;
}
.section--leftrightfloat--left .block--video--icon {
	position:absolute!important;
	top:50%;
	left:50%;
	-webkit-transform:translateY(-50%) translateX(-50%);
	-moz-transform:translateY(-50%) translateX(-50%);
	transform:translateY(-50%) translateX(-50%);
}


.section--leftright {
	img {
		height: auto !important;
	}
}


.section--leftrightoverlap {

	@include media-query('>tablet') {
		.section--leftrightoverlap--media {
			display:block!important;
			position:absolute;
			top:0;
			width: percentage(7/12);
			height:100%;
		}
		.section--leftrightoverlap--media--wrapper {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
		&.section--leftrightoverlap--right {
			.section--leftrightoverlap--media {
				right:0;
			}
		}
		&.section--leftrightoverlap--left {
			.section--leftrightoverlap--media {
				left:0;
			}
		}
	}

	@include media-query('palm') {
		.section--leftrightoverlap--media--wrapper {
			background:none;
		}
	}

}


.section--cards {
	&--column {
		filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.1));
		&--link {
			display: block;
			transform: translateY(0);
			transition: transform 0.3s ease-in-out;
			&:hover {
				transform: translateY(-5px);
				.simple:after {
					transform: translateX(10px);
				}
			}
		}
		&--body {
			display: block;
			&--notches {
				clip-path: polygon(0 0, calc( 100% - 50px ) 0, 100% 60px, 100% 100%, 0 100%);
			}
		}
	}
}

// main: ../../production.scss

.section--columns {
	.section--columns--divider--vertical {
		display:inline;
		position:absolute;
		left:auto;
		top:auto;
		width:1px;
		background: color('black');
		opacity:.15;
		filter:alpha(opacity=15);
		height:0px;
	}
	.section--columns--divider--horizontal {
		display:block;
		position:absolute;
		background: color('black');
		opacity:.15;
		filter:alpha(opacity=15);
		height:1px;
		top:0px;
		left:0px;
		right:0px;
	}
	.grid.hard .section--columns--divider--horizontal,
	.grid.hard--sides .section--columns--divider--horizontal {
		left: $short;
		right: $short;
	}
}


@include media-query('>tablet') {
	.section--leftrightfloat--media {
		display:block!important;
		position:absolute;
		top:0;
		width: 31.833333333%;
		height:100%;
	}
	.section--leftrightfloat--media--wrapper {
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
	}
	.section--leftrightfloat--right div.section--leftrightfloat--media {
		left:0;
	}
	.section--leftrightfloat--left div.section--leftrightfloat--media {
		right:0;
	}
	.section--leftrightfloat--media--video--icon {
		position:absolute;
		top:50%;
		left:50%;
		-webkit-transform:translateY(-50%) translateX(-50%);
		-moz-transform:translateY(-50%) translateX(-50%);
		transform:translateY(-50%) translateX(-50%);
	}
}

@include media-query('palm') {
	.section--leftrightfloat--media--wrapper {
		background:none;
	}
}

.section--industryselector {
	.selector--holder {
		.tabs--holder {
			.industry--selector--tabs {
				a {
					transition: background .3s ease-in-out;
					padding: 10px 0 10px 10px;
					margin: 0 !important;
					display: block;
					font-size: 18px;
					&.active {
						color: color('blue');
						background: color('lightgrey');
					}
					&:hover {
						color: color('blue');
						background: color('lightgrey');
					}
				}
			}
		}
		.tab--content--holder {
			@include media-query('lap-and-up') {
				right:-200px;
			}
		}
	}
}

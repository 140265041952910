// main: ../../production.scss

.section--fullwidthstatistic--stat {
	display:block;
	position:relative;
	width:100%;
	overflow:hidden;
}
.section--fullwidthstatistic--stat--outer {
	display:block;
	position:absolute;
	z-index:2;
	top:6%;
	left:6%;
	right:6%;
	bottom:6%;
	border-radius:50%;
}
.section--fullwidthstatistic--stat--inner {
	display:block;
	position:absolute;
	top:4%;
	left:4%;
	right:4%;
	bottom:4%;
	border-radius:50%;
}
.section--fullwidthstatistic--stat--circle {
	display:block;
	position:absolute;
	top:-3%;
	left:-3%;
	right:-3%;
	bottom:-3%;
	z-index:3;
	transform:rotate(-90deg);
	border-radius: 100%;
}
.section--fullwidthstatistic--stat--circle svg {
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
.section--fullwidthstatistic--stat--circle svg .section--fullwidthstatistic--stat--circle--inner {
	stroke-dasharray: 227;
	stroke-dashoffset:0;
	stroke-width:5%;
	stroke-linecap: round;
	color: color('lightgrey')
}
.section--fullwidthstatistic--stat:not(.counterticker--parent--counterticked) .section--fullwidthstatistic--stat--circle svg .section--fullwidthstatistic--stat--circle--inner {
	stroke-dashoffset: 227!important;
}
.section--fullwidthstatistic--stat--main {
	display:block;
	position:absolute;
	top:1%;
	left:1%;
	right:1%;
	bottom:1%;
	border-radius:50%;
	border:5px solid color('lightgrey');
}
.section--fullwidthstatistic--stat--body {
	display:block;
	position:absolute;
	z-index:4;
	top:50%;
	left:0%;
	width:100%;
	text-align:center;
	transform:translateY(-50%);
}
.section--fullwidthstatistic--number {
	display: flex;
	justify-content: center;
  min-height:1em;
	font-size:60px;
	line-height:1em;
	color:color('primary');
	font-family:font('reading');
	font-weight:bold;
}
.section--fullwidthstatistic--prefix {
	display: inline-block;
	font-weight:bold;
	font-size: .5em;
	vertical-align: super;
}
.section--fullwidthstatistic--number--inner {
	display: inline-block;
	line-height:inherit;
}
.section--fullwidthstatistic--suffix {
	display: inline-block;
	font-weight: bold;
	font-size: .5em;
	line-height: 1em;
	vertical-align: super;
}
.section--fullwidthstatistic--label {
	text-emphasis: center;
	display:block;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	font-family:font('reading');
	font-weight:normal;
	font-size:24px;
	line-height:1.2em;
	margin-top:5px;
	margin-top:.2em;
	color:color('black');
}
.section--fullwidthstatistic--label--inner {
	display:block;
	font-size:inherit;
	line-height:1.5em;
}

.section--fullwidthstatistic--number--l .section--fullwidthstatistic--number--inner {
	font-size:.85em;
}
.section--fullwidthstatistic--number--xl .section--fullwidthstatistic--number--inner {
	font-size:.8em;
}
.section--fullwidthstatistic--number--xxl .section--fullwidthstatistic--number--inner {
	font-size:.7em;
}
.section--fullwidthstatistic--number--xxxl .section--fullwidthstatistic--number--inner {
	font-size:.6em;
}

// .section--fullwidthstatistic--label--l .section--fullwidthstatistic--label--inner {
// 	font-size:.85em;
// }
// .section--fullwidthstatistic--label--xl .section--fullwidthstatistic--label--inner {
// 	font-size:.8em;
// }
// .section--fullwidthstatistic--label--xxl .section--fullwidthstatistic--label--inner {
// 	font-size:.7em;
// }
// .section--fullwidthstatistic--label--xxxl .section--fullwidthstatistic--label--inner {
// 	font-size:.8em;
// }

@include media-query('lap') {
	.section--fullwidthstatistic--number {
		font-size: 4.5vw;
	}
	.section--fullwidthstatistic--label {
		font-size:1.7vw;
	}
}

@include media-query('portable') {
	.section--fullwidthstatistic--number {
		font-size: 6.5vw;
	}
	.section--fullwidthstatistic--label {
		font-size:2.7vw;
		padding: .5em;
		.section--fullwidthstatistic--label--inner {
			font-size:.6em !important;
		}
	}
}

@include media-query('palm') {
	.section--fullwidthstatistic--stat {
		display:block!important;
		max-width:100%!important;
		width:400px!important;
		margin-left:auto!important;
		margin-right:auto!important;
	}
	.section--fullwidthstatistic--number {
		font-size: 68px;
	}
	.section--fullwidthstatistic--label {
		font-size:26px;
	}
}

@media (max-width:424px) {
	.section--fullwidthstatistic--number {
		font-size: 15vw;
	}
	.section--fullwidthstatistic--label {
		font-size:6.2vw;
	}
}

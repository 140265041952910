// Download List
.section--product--tabdrawer {
	transition: all .3s ease-in-out;
	position: absolute;
	right:-350px;
	width: 350px;
	min-height: 350px;
	position: fixed;
	// top: 25vh;
	z-index: 9999;
	padding:15px 10px;

	.tabdrawer--toggle {
		cursor: pointer;
		position: absolute;
		z-index: 999;
		padding: 15px 30px;
		color: color('white');
		transform-origin: bottom right;
		transform: rotate(-90deg);
		z-index: 999;
		i {
			color: color('white');
		}
	}
	&.open {
		right: 0;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include media-query('palm') {
		top: unset;
		right: unset;
		max-width: 100%;
		min-height: unset;
		.tabdrawer--toggle {
			position: absolute;
			width: 100%;
			transform: rotate(0);
		}
	}
}

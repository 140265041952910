// main: ../../production.scss

.section--imagebannermosaic--inner {
    display:block;
    position:absolute;
    top:-4px;
    left:-4px;
    bottom:-4px;
    right:-4px;
  }
  .section--imagebannermosaic--item {
    display:block;
    position:absolute;
  }
  .section--imagebannermosaic--item--inner {
    display:block;
    position:absolute;
    top:4px;
    left:4px;
    right:4px;
    bottom:4px;
    box-shadow:none!important;
    outline:none!important;
  }
  .section--imagebannermosaic--item--1-1 {
    top:0%;
    left:0%;
    right:0%;
    bottom:0%;
  }

  .section--imagebannermosaic--item--2-1 {
    top:0%;
    left:0%;
    right:50%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--2-2 {
    top:0%;
    left:50%;
    right:0%;
    bottom:0%;
  }

  .section--imagebannermosaic--item--3-1 {
    top:0%;
    left:0%;
    right:66.66666%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--3-2 {
    top:0%;
    left:33.33333%;
    right:33.33333%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--3-3 {
    top:0%;
    left:66.66666%;
    right:0%;
    bottom:0%;
  }

  .section--imagebannermosaic--item--4-1 {
    top:0%;
    left:0%;
    right:66.66666%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--4-2 {
    top:0%;
    left:33.33333%;
    right:33.33333%;
    bottom:50%;
  }
  .section--imagebannermosaic--item--4-3 {
    top:50%;
    left:33.33333%;
    right:33.33333%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--4-4 {
    top:0%;
    left:66.66666%;
    right:0%;
    bottom:0%;
  }

  .section--imagebannermosaic--item--5-1 {
    top:0%;
    left:0%;
    right:66.66666%;
    bottom:50%;
  }
  .section--imagebannermosaic--item--5-2 {
    top:50%;
    left:0%;
    right:66.66666%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--5-3 {
    top:0%;
    left:33.33333%;
    right:33.33333%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--5-4 {
    top:0%;
    left:66.66666%;
    right:0%;
    bottom:50%;
  }
  .section--imagebannermosaic--item--5-5 {
    top:50%;
    left:66.66666%;
    right:0%;
    bottom:0%;
  }

  .section--imagebannermosaic--item--6-1 {
    top:0%;
    left:0%;
    right:75%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--6-2 {
    top:0%;
    left:25%;
    right:50%;
    bottom:50%;
  }
  .section--imagebannermosaic--item--6-3 {
    top:50%;
    left:25%;
    right:50%;
    bottom:00%;
  }
  .section--imagebannermosaic--item--6-4 {
    top:0%;
    left:50%;
    right:25%;
    bottom:0%;
  }
  .section--imagebannermosaic--item--6-5 {
    top:0%;
    left:75%;
    right:0%;
    bottom:50%;
  }
  .section--imagebannermosaic--item--6-6 {
    top:50%;
    left:75%;
    right:0%;
    bottom:0%;
  }